.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0.5em 1.5em;
}

.App-header h1 {
  margin-right: auto;
}

.App-header-username {
  margin: 0.2em 1em;
}
