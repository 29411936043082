.container {
  border: 1px solid #ccc;
  margin: 0.5em;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.containerExpanded {
  border: 1px solid #333;
}
.itemHead {
  padding: 0.5em 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.itemHeadExpanded {
  background-color: #eee;
  border-bottom: 1px solid #333;
}

.itemHead h2 {
  margin: 0;
}

.itemHeadMeta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.itemBody {
  padding: 0.5em 1em;
}

.itemBody table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
}
table.itemBodyAttendanceTable {
  width: 100%;
  border: 2px solid black;
  border-collapse: collapse;
}
.itemBodyAttendanceTable th {
  text-align: left;
  padding: 3px 5px;
  border-bottom: 1px solid black;
}
.itemBodyAttendanceTable td {
  border-top: 1px solid black;
  text-align: left;
  font-weight: normal;
  padding: 3px 5px;
}
.itemBodyAttendanceTableCheck {
  border-top: 1px solid black;
  text-align: center;
  padding: 3px 5px;
  border-left: 1px solid black;
}

.itemBody h3 {
  margin-bottom: 0;
  font-weight: normal;
}
.itemBody h3 strong {
  text-transform: uppercase;
}

.button {
  padding: 0.5em 2em;
  margin: 0.5em;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.rejectButton:hover,
.rejectButton:focus {
  background-color: #a00;
}

.rejectButton {
  border: 0;
  background-color: #d00;
  color: white;
}

.acceptButton:hover,
.acceptButton:focus {
  background-color: #060;
}

.acceptButton {
  border: 0;
  background-color: #090;
  color: white;
}

.pill {
  background-color: dimgray;
  color: white;
  border-radius: 8px;
  padding: 2px 6px;
  font-size: 13px;
  font-weight: normal;
  margin: auto 5px;
}
